import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { statusFilters, statusProps } from 'src/common/types'
import { Tag } from 'src/components/Tag'
import { UserHyperlink } from 'src/components/UserHyperlink'
import { generateColumnTitle } from 'src/helpers/utils'
import { useColumnSearch } from 'src/hooks/useColumnSearch'
import { useFilteredInfo } from 'src/hooks/useFilteredInfo'
import { CarePartnerInfo } from 'src/store/APIs/carePartners/types'
import { UserType } from 'src/store/APIs/types'

import { strings } from './strings'
import { CarePartnersDataIndex } from './types'

export const useCarePartnersColumns = () => {
  const { generateColumnSearchProps } = useColumnSearch()
  const { filteredInfo, ...restFilteredInfo } = useFilteredInfo()

  const columns: ColumnsType<CarePartnerInfo> = [
    {
      ...generateColumnTitle(
        CarePartnersDataIndex.full_name,
        strings.cpColumnLabel,
      ),
      ...generateColumnSearchProps(CarePartnersDataIndex.full_name),
      filteredValue: filteredInfo?.filters?.full_name || null,
      render(_, { full_name, id, permissions }) {
        return (
          <UserHyperlink
            user={{
              full_name,
              id,
              permissions,
              user_type: UserType.mobile_caregiver,
            }}
          />
        )
      },
      sorter: true,
    },
    {
      ...generateColumnTitle(
        CarePartnersDataIndex['cms_navigators.full_name'],
        strings.navigatorColumnLabel,
      ),
      ...generateColumnSearchProps(
        CarePartnersDataIndex['cms_navigators.full_name'],
      ),
      filteredValue: filteredInfo.filters?.['cms_navigators.full_name'] || null,
      render: (_, { cms_navigator }) => cms_navigator?.full_name,
      sorter: true,
    },
    {
      ...generateColumnTitle(
        CarePartnersDataIndex.phone_number,
        strings.phoneNumberColumnLabel,
      ),
      ...generateColumnSearchProps(CarePartnersDataIndex.phone_number),
      filteredValue: filteredInfo?.filters?.phone_number || null,
      sorter: true,
    },
    {
      ...generateColumnTitle(
        CarePartnersDataIndex.email,
        strings.emailColumnLabel,
      ),
      ...generateColumnSearchProps(CarePartnersDataIndex.email),
      filteredValue: filteredInfo?.filters?.email || null,
      sorter: true,
    },
    {
      ...generateColumnTitle(
        CarePartnersDataIndex.content_language,
        strings.languageColumnLabel,
      ),
      width: 150,
    },
    {
      ...generateColumnTitle(
        CarePartnersDataIndex.status,
        strings.statusColumnLabel,
      ),
      filteredValue: filteredInfo?.filters?.status || null,
      filters: statusFilters,
      render(value) {
        return (
          <Tag color={statusProps(value).color}>{statusProps(value).title}</Tag>
        )
      },
    },
  ]
  return {
    filteredInfo,
    ...restFilteredInfo,
    columns,
  }
}
