import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { deserializeShortFormattedDate } from 'src/common/types'
import { Button } from 'src/components/Button'
import { generateColumnTitle } from 'src/helpers/utils'
import { useColumnSearch } from 'src/hooks/useColumnSearch'
import { useFilteredInfo } from 'src/hooks/useFilteredInfo'
import { SuicidalityEmail } from 'src/store/APIs/suicidalityEmails/types'

import { strings } from './strings'
import styles from './suicidalityEmails.module.scss'
import { SuicidalityEmailsDataIndex } from './types'

export const useSuicidalityEmailsColumns = (
  openView?: (id: string) => () => void,
) => {
  const { generateColumnSearchProps } = useColumnSearch()
  const { filteredInfo, ...restFilteredInfo } = useFilteredInfo()

  const columns: ColumnsType<SuicidalityEmail> = [
    {
      ...generateColumnTitle(
        SuicidalityEmailsDataIndex.email,
        strings.emailColumnLabel,
      ),
      ...generateColumnSearchProps(SuicidalityEmailsDataIndex.email),
      filteredValue: filteredInfo.filters?.email || null,
      render: (text: string, record: SuicidalityEmail) => (
        <Button
          className={styles.suicidalityEmailsLink}
          type="link"
          onClick={openView?.(record.id)}>
          {text}
        </Button>
      ),
      sorter: true,
      width: 300,
    },
    {
      ...generateColumnTitle(
        SuicidalityEmailsDataIndex.updated_at,
        strings.updatedAtColumnLabel,
      ),
      render: value => deserializeShortFormattedDate(value),
      sorter: true,
      width: 150,
    },
    {
      ...generateColumnTitle(
        SuicidalityEmailsDataIndex.created_at,
        strings.createdAtColumnLabel,
      ),
      render: value => deserializeShortFormattedDate(value),
      sorter: true,
      width: 150,
    },
  ]

  return {
    columns,
    filteredInfo,
    ...restFilteredInfo,
  }
}
