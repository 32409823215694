import { Form } from 'antd'
import React from 'react'
import { TextInput } from 'src/components/TextInput'

import { strings } from './strings'
import { yupSync } from './validations'

interface AddSuicidalityEmailProps {}

export const AddSuicidalityEmail: React.FC<AddSuicidalityEmailProps> = () => {
  return (
    <Form.Item name="email" rules={[yupSync]}>
      <TextInput label={strings.emailLabel} required />
    </Form.Item>
  )
}
