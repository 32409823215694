export enum SurvivorsDataIndex {
  'full_name' = 'full_name',
  'email' = 'email',
  'hospitals.name' = 'hospitals.name',
  'phone_number' = 'phone_number',
  'care_partner' = 'care_partner',
  'status' = 'status',
  'alerts' = 'alerts',
  'cms_navigators.full_name' = 'cms_navigators.full_name',
  'content_language' = 'content_language',
}
