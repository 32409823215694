import { baseApi } from 'src/store/APIs'

import {
  addSuicidalityEmail,
  deleteSuicidalityEmail,
  editSuicidalityEmail,
  getSuicidalityEmail,
  getSuicidalityEmails,
} from './actions'

export const suicidalityEmailsApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    addSuicidalityEmail: addSuicidalityEmail(builder),
    deleteSuicidalityEmail: deleteSuicidalityEmail(builder),
    editSuicidalityEmail: editSuicidalityEmail(builder),
    getSuicidalityEmail: getSuicidalityEmail(builder),
    getSuicidalityEmails: getSuicidalityEmails(builder),
  }),
})

export const {
  useLazyGetSuicidalityEmailsQuery,
  useGetSuicidalityEmailQuery,
  useLazyGetSuicidalityEmailQuery,
  useAddSuicidalityEmailMutation,
  useEditSuicidalityEmailMutation,
  useDeleteSuicidalityEmailMutation,
} = suicidalityEmailsApi
