import { HttpMethod } from 'src/store/helpers'
import { format } from 'util'

import {
  Builder,
  endpointWithFilters,
  PaginatedItems,
  ParamsWithFilters,
  PerformActionResponse,
  performActionTransformResponse,
  provideListToTag,
  Tags,
} from '../types'
import {
  Endpoints,
  SuicidalityEmail,
  suicidalityEmailsDeserializer,
} from './types'

export const getSuicidalityEmails = (builder: Builder) =>
  builder.query<PaginatedItems<SuicidalityEmail>, ParamsWithFilters>({
    providesTags: provideListToTag(Tags.SuicidalityEmails),
    query: endpointWithFilters(Endpoints.SuicidalityEmails),
    transformResponse: suicidalityEmailsDeserializer,
  })

export const getSuicidalityEmail = (builder: Builder) =>
  builder.query<SuicidalityEmail, { id: string }>({
    providesTags: (_, __, { id }) => [{ id, type: Tags.SuicidalityEmails }],
    query: ({ id }) => format(Endpoints.SuicidalityEmail, id),
    transformResponse: ({ suicidality_email }) => suicidality_email,
  })

export const addSuicidalityEmail = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { body: { email: string } }>({
    invalidatesTags: provideListToTag(Tags.SuicidalityEmails),
    query: ({ body }) => ({
      body: { suicidality_email: body },
      method: HttpMethod.Post,
      url: Endpoints.SuicidalityEmails,
    }),
    transformResponse: performActionTransformResponse,
  })

export const editSuicidalityEmail = (builder: Builder) =>
  builder.mutation<
    PerformActionResponse,
    { body: { email: string }; id: string }
  >({
    invalidatesTags: () => [{ type: Tags.SuicidalityEmails }],
    query: ({ body, id }) => ({
      body: { suicidality_email: body },
      method: HttpMethod.Put,
      url: format(Endpoints.SuicidalityEmail, id),
    }),
    transformResponse: performActionTransformResponse,
  })

export const deleteSuicidalityEmail = (builder: Builder) =>
  builder.mutation<PerformActionResponse, { id: string }>({
    invalidatesTags: () => [{ type: Tags.SuicidalityEmails }],
    query: ({ id }) => ({
      method: HttpMethod.Delete,
      url: format(Endpoints.SuicidalityEmail, id),
    }),
    transformResponse: performActionTransformResponse,
  })
