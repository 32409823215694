import { Rule } from 'antd/es/form'
import { generalStrings } from 'src/common/generalStrings'
import { yupSyncGenerator } from 'src/common/helpers'
import * as yup from 'yup'

import { strings } from './strings'

const schema = yup.object({
  email: yup
    .string()
    .required(generalStrings.requiredFieldValidation)
    .email(strings.invalidEmailValidation),
})

export const yupSync: Rule = yupSyncGenerator(schema)
