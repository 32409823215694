import { ColumnsType } from 'antd/es/table'
import React from 'react'
import { alertsFilters, statusFilters, statusProps } from 'src/common/types'
import { Tag } from 'src/components/Tag'
import { UserHyperlink } from 'src/components/UserHyperlink'
import { generateColumnTitle } from 'src/helpers/utils'
import { useColumnSearch } from 'src/hooks/useColumnSearch'
import { useFilteredInfo } from 'src/hooks/useFilteredInfo'
import { InfoSurvivorDataSource } from 'src/store/APIs/survivors/types'
import { UserType } from 'src/store/APIs/types'

import { strings } from './strings'
import { SurvivorsDataIndex } from './types'

export const useSurvivorColumns = () => {
  const { generateColumnSearchProps } = useColumnSearch()
  const { filteredInfo, ...restFilteredInfo } = useFilteredInfo()

  const columns: ColumnsType<InfoSurvivorDataSource> = [
    {
      ...generateColumnTitle(
        SurvivorsDataIndex.full_name,
        strings.survivorColumnLabel,
      ),
      ...generateColumnSearchProps(SurvivorsDataIndex.full_name),
      filteredValue: filteredInfo.filters?.full_name || null,
      fixed: 'left',
      render: (_, { full_name, id, permissions }) => (
        <UserHyperlink
          user={{
            full_name,
            id,
            permissions,
            user_type: UserType.mobile_survivor,
          }}
        />
      ),
      sorter: true,
    },
    {
      ...generateColumnTitle(
        SurvivorsDataIndex.alerts,
        strings.alertsColumnLabel,
      ),
      filteredValue: filteredInfo.filters?.alerts || null,
      filters: alertsFilters,
      render(value) {
        return value ? (
          <Tag color="red">{strings.tagYes}</Tag>
        ) : (
          <Tag color="gray">{strings.tagNo}</Tag>
        )
      },
      width: 120,
    },
    {
      ...generateColumnTitle(
        SurvivorsDataIndex['cms_navigators.full_name'],
        strings.navigatorColumnLabel,
      ),
      ...generateColumnSearchProps(
        SurvivorsDataIndex['cms_navigators.full_name'],
      ),
      filteredValue: filteredInfo.filters?.['cms_navigators.full_name'] || null,
      render: (_, { cms_navigator }) => cms_navigator?.full_name,
      sorter: true,
    },
    {
      ...generateColumnTitle(
        SurvivorsDataIndex.phone_number,
        strings.phoneNumberColumnLabel,
      ),
      ...generateColumnSearchProps(SurvivorsDataIndex.phone_number),
      filteredValue: filteredInfo.filters?.phone_number || null,
      // sorter: true, TODO: Uncomment this when BE fix the sorter
    },
    {
      ...generateColumnTitle(
        SurvivorsDataIndex.email,
        strings.emailColumnLabel,
      ),
      ...generateColumnSearchProps(SurvivorsDataIndex.email),
      filteredValue: filteredInfo.filters?.email || null,
      sorter: true,
    },
    {
      ...generateColumnTitle(
        SurvivorsDataIndex.care_partner,
        strings.carePartnerColumnLabel,
      ),
      ...generateColumnSearchProps(SurvivorsDataIndex.care_partner),
      filteredValue: filteredInfo.filters?.care_partner || null,
      render(_, { care_partner, care_partner_id, permissions }) {
        return (
          care_partner_id && (
            <UserHyperlink
              user={{
                full_name: care_partner,
                id: care_partner_id,
                permissions,
                user_type: UserType.mobile_caregiver,
              }}
            />
          )
        )
      },
      sorter: true,
    },
    {
      ...generateColumnTitle(
        SurvivorsDataIndex.content_language,
        strings.languageColumnLabel,
      ),
    },
    {
      ...generateColumnTitle(
        SurvivorsDataIndex.status,
        strings.statusColumnLabel,
      ),
      filteredValue: filteredInfo.filters?.status || null,
      filters: statusFilters,
      render(value) {
        return (
          <Tag color={statusProps(value).color}>{statusProps(value).title}</Tag>
        )
      },
    },
    {
      ...generateColumnTitle(
        SurvivorsDataIndex['hospitals.name'],
        strings.originatingHospitalColumnLabel,
      ),
      ...generateColumnSearchProps(SurvivorsDataIndex['hospitals.name']),
      filteredValue: filteredInfo.filters?.['hospitals.name'] || null,
      render: (_, { hospital }) => hospital?.name,
      sorter: true,
    },
  ]

  return {
    filteredInfo,
    ...restFilteredInfo,
    columns,
  }
}
