import React from 'react'
import { deserializeShortFormattedDate } from 'src/common/types'
import { InfoTable, InfoTableRow } from 'src/components/InfoTable'
import { SuicidalityEmail } from 'src/store/APIs/suicidalityEmails/types'

import { strings } from './strings'

interface ViewSuicidalityEmailProps {
  data?: SuicidalityEmail
}

export const ViewSuicidalityEmail: React.FC<ViewSuicidalityEmailProps> = ({
  data,
}) => {
  const contentStyle = { width: '80%' }

  const rows: InfoTableRow[] = [
    {
      content: data?.email,
      label: strings.emailColumn,
    },
    {
      content: data?.created_at
        ? deserializeShortFormattedDate(data?.created_at)
        : undefined,
      label: strings.createdAtColumn,
    },
    {
      content: data?.updated_at
        ? deserializeShortFormattedDate(data?.updated_at)
        : undefined,
      label: strings.updatedAtColumn,
    },
  ]
  return <InfoTable rows={rows} contentStyle={contentStyle} />
}
