import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb'
import React from 'react'
import { generalStrings } from 'src/common/generalStrings'
import { SectionNames } from 'src/common/types'
import { Button } from 'src/components/Button'
import { DrawerWithState } from 'src/components/DrawerWithState'
import { PaginatedTable } from 'src/components/PaginatedTable'
import { SubHeader } from 'src/components/SubHeader'
import { useDrawer } from 'src/hooks/useDrawer'
import { RouteName } from 'src/routes'
import {
  useAddSuicidalityEmailMutation,
  useDeleteSuicidalityEmailMutation,
  useEditSuicidalityEmailMutation,
  useLazyGetSuicidalityEmailQuery,
  useLazyGetSuicidalityEmailsQuery,
} from 'src/store/APIs/suicidalityEmails'

import { areFiltersOn } from '../Learn/types'
import { AddSuicidalityEmail } from './AddSuicidalityEmail'
import { useSuicidalityEmailsColumns } from './columns'
import { strings } from './strings'
import styles from './suicidalityEmails.module.scss'
import { ViewSuicidalityEmail } from './ViewSuicidalityEmail'

const mockedBreadcrumbRoutes: BreadcrumbItemType[] = [
  { breadcrumbName: generalStrings.dashboardLabel, path: RouteName.Home },
  { breadcrumbName: strings.title, path: RouteName.SuicidalityEmails },
]

export const SuicidalityEmails: React.FC = () => {
  const [triggerAddSuicidalityEmailMutation, { isLoading: loadingAdd }] =
    useAddSuicidalityEmailMutation()
  const [triggerEditSuicidalityEmailMutation, { isLoading: loadingEdit }] =
    useEditSuicidalityEmailMutation()
  const [triggerDeleteSuicidalityEmailMutation, { isLoading: loadingDelete }] =
    useDeleteSuicidalityEmailMutation()

  const { open, onCloseDrawer, selectedId, openView, showDrawer } = useDrawer()

  const { resetFilters, ...columnsAndFilters } =
    useSuicidalityEmailsColumns(openView)
  const filtersOn = areFiltersOn(columnsAndFilters.filteredInfo.filters)

  return (
    <div className={styles.container}>
      <SubHeader
        className={styles.subHeader}
        routes={mockedBreadcrumbRoutes}
        section={SectionNames.Configuration}
        title={strings.title}>
        <div className={styles.subHeaderButtons}>
          <Button onClick={resetFilters} disabled={!filtersOn}>
            {generalStrings.clearFiltersButtonLabel}
          </Button>
          <Button onClick={showDrawer} type="primary">
            {strings.addSuicidalityEmailButton}
          </Button>
        </div>
      </SubHeader>
      <DrawerWithState
        open={open}
        onCloseDrawer={onCloseDrawer}
        selectedId={selectedId}
        RenderAdd={AddSuicidalityEmail}
        RenderView={ViewSuicidalityEmail}
        resourceName={strings.suicidalityEmailDrawerTitle}
        useLazyGetResource={useLazyGetSuicidalityEmailQuery}
        triggerDeleteResourceMutation={triggerDeleteSuicidalityEmailMutation}
        isLoadingLeftButton={loadingDelete}
        deleteModalTitle={strings.deleteModalTitle}
        triggerAddResourceMutation={triggerAddSuicidalityEmailMutation}
        triggerEditResourceMutation={triggerEditSuicidalityEmailMutation}
        isLoadingRightButton={loadingAdd || loadingEdit}
      />
      <PaginatedTable
        className={styles.table}
        useLazyQuery={useLazyGetSuicidalityEmailsQuery}
        {...columnsAndFilters}
      />
    </div>
  )
}
