import { transformPhoneNumber } from 'src/common/helpers'
import {
  BiologicalSex,
  CustomDate,
  optionalDateDeserializer,
  Status,
  TabTypes,
} from 'src/common/types'
import { LearnTrackResponse } from 'src/components/LearnView/types'
import {
  BaseUser,
  baseUserDeserializer,
  BaseUserResponse,
  Entity,
  Languages,
  Model,
  PaginatedItems,
  PaginationResponse,
  permissionsDeserializer,
  tabPermissionDeserializer,
  UserType,
} from 'src/store/APIs/types'

import { Survivor } from '../survivors/types'

export enum Endpoints {
  GetCarePartners = '/cms/v1/mobile_caregivers.json',
  GetCarePartner = '/cms/v1/mobile_caregivers/%s.json',
  GetCarePartnerUnits = '/cms/v1/mobile_caregivers/%s/lessons.json',
  NotifyUncompletedLesson = '/cms/v1/mobile_caregivers/%s/lessons/notify_uncompleted_lessons.json',
  GetCarePartnerGroups = '/cms/v1/mobile_caregivers/%s/groups.json',
  ResetPassword = '/cms/v1/mobile_caregivers/%s/account_management/reset_password.json',
  SendSignUpEmail = '/cms/v1/mobile_caregivers/%s/account_management/send_sign_up_email.json',
  SendSignUpSMS = '/cms/v1/mobile_caregivers/%s/account_management/send_sign_up_sms.json',
  SendAppInvitationEmail = '/cms/v1/mobile_caregivers/%s/account_management/send_app_invitation_email.json',
  SendAppInvitationSMS = '/cms/v1/mobile_caregivers/%s/account_management/send_app_invitation_sms.json',
  ChangeStatus = '/cms/v1/mobile_caregivers/%s/account_management/change_status.json',
  NewCarePartner = '/cms/v1/mobile_caregivers.json',
  UpdateCarePartner = '/cms/v1/mobile_caregivers/%s.json',
  GetConversations = '/cms/v1/mobile_caregivers/%s/navigator_conversations/%s.json',
  SendConversationMessage = '/cms/v1/mobile_caregivers/%s/navigator_conversations/messages/new.json',
  EditConversationMessage = '/cms/v1/mobile_caregivers/%s/navigator_conversations/messages/%s.json',
  DeleteConversationMessage = '/cms/v1/mobile_caregivers/%s/navigator_conversations/messages/%s.json',
  DeleteCarePartner = '/cms/v1/mobile_caregivers/%s.json',
  SendWelcomeEmail = '/cms/v1/mobile_caregivers/%s/account_management/welcome_mail.json',
  SendGraduationEmail = '/cms/v1/mobile_caregivers/%s/account_management/graduation_mail.json',
}

export interface CarePartnerInfoResponse extends Entity {
  id: string
  email: string
  first_name: string
  last_name: string
  phone_number: string
  status: string
  preferred_phone: string
  cms_navigator?: BaseUserResponse
  content_language?: Languages
}

export interface CarePartnerInfo extends Entity {
  id: string
  email: string
  full_name: string
  phone_number: string
  status: Status
  link: string
  preferred_phone: string
  cms_navigator?: BaseUser
  'cms_navigators.full_name'?: string
  content_language?: Languages
}

export interface GetCarePartnersResponse extends Model {
  mobile_caregivers: CarePartnerInfoResponse[]
  pagination: PaginationResponse
}

export const carePartnerInfoDeserializer = (
  data: CarePartnerInfoResponse,
): CarePartnerInfo => {
  return {
    cms_navigator: data.cms_navigator
      ? baseUserDeserializer(data.cms_navigator)
      : undefined,
    'cms_navigators.full_name': data.cms_navigator?.full_name,
    content_language: data.content_language,
    email: data.email,
    full_name: `${data.first_name} ${data.last_name}`,
    id: data.id,
    link: `/care_partners/${data.id}`,
    permissions: permissionsDeserializer(data.permissions),
    phone_number: transformPhoneNumber(data.phone_number)!,
    preferred_phone: data.preferred_phone,
    status: data.status as Status,
  }
}

export const carePartnersInfoDeserializer = (
  data: CarePartnerInfoResponse[],
): CarePartnerInfo[] => data.map(carePartnerInfoDeserializer)

export const getCarePartnersDeserializer = (
  data: GetCarePartnersResponse,
): PaginatedItems<CarePartnerInfo> => {
  return {
    items: carePartnersInfoDeserializer(data.mobile_caregivers),
    model_permissions: data.model_permissions,
    pagination: data.pagination,
  }
}

export interface CarePartnerResponse extends Entity {
  mobile_user_id: string
  tabs_permissions?: TabTypes[]
  id: string
  biological_sex: string | null
  email: string
  first_name: string
  last_name: string
  limitations: string
  mobile_survivors: BaseUserResponse[]
  preferred_name: string | null
  preferred_pronoun: string | null
  status: string
  test_user: boolean | null
  time_zone: string | null
  phone_number: string | null
  preferred_phone: string
  home_phone: string | null
  welcome_mail_date: string | null
  graduation_mail_date: string | null
  learn_track: LearnTrackResponse
  content_language: Languages
}

export interface CarePartner extends Entity {
  tabs_permissions?: TabTypes[]
  home_phone_preferred?: string
  phone_number_preferred?: string
  id: string
  biological_sex: BiologicalSex | undefined
  email: string
  first_name: string
  last_name: string
  limitations: string
  survivors: Partial<Survivor>[]
  phone_number: string | undefined
  preferred_phone: string
  home_phone: string | undefined
  preferred_name: string | undefined
  preferred_pronoun: string | undefined
  status: Status
  test_user: boolean
  full_name: string
  welcome_mail_date: CustomDate | undefined
  graduation_mail_date: CustomDate | undefined
  time_zone: string | undefined
  learn_track: LearnTrackResponse
  mobile_user_id?: string
  content_language: Languages
}

export const carePartnerDeserializer = (
  data: CarePartnerResponse,
): CarePartner => {
  return {
    biological_sex: data.biological_sex as BiologicalSex,
    content_language: data.content_language,
    email: data.email,
    first_name: data.first_name,
    full_name: `${data.first_name} ${data.last_name} `,
    graduation_mail_date: optionalDateDeserializer(data.graduation_mail_date),
    home_phone: data.home_phone
      ? transformPhoneNumber(data.home_phone)
      : undefined,
    id: data.id,
    last_name: data.last_name,
    learn_track: data.learn_track,
    limitations: data.limitations,
    mobile_user_id: data.mobile_user_id,
    permissions: permissionsDeserializer(data.permissions),
    phone_number: data.phone_number
      ? transformPhoneNumber(data.phone_number)
      : undefined,
    preferred_name: data.preferred_name ?? '',
    preferred_phone: data.preferred_phone,
    preferred_pronoun: data.preferred_pronoun ?? '',
    status: data.status as Status,
    survivors: data.mobile_survivors.map(elem => ({
      full_name: `${elem.first_name} ${elem.last_name}`,
      hospital: elem.hospital,
      id: elem.id,
      permissions: elem.permissions,
      user_type: elem.user_type as UserType,
    })),
    tabs_permissions: tabPermissionDeserializer(data),
    test_user: data.test_user ?? false,
    time_zone: data.time_zone ?? undefined,
    welcome_mail_date: optionalDateDeserializer(data.welcome_mail_date),
  }
}

export const carePartnersDeserializer = (
  data: CarePartnerResponse[],
): CarePartner[] => data.map(carePartnerDeserializer)

export interface CarePartnerPayload {
  mobile_caregiver: {
    email: string
    first_name: string
    last_name: string
    phone_number: string
    biological_sex: BiologicalSex | undefined
    limitations: string
    address: string
    preferred_name: string | undefined
    preferred_pronoun: string | undefined
    mobile_survivor_ids: string[]
    test_user: boolean
  }
}
