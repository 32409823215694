import { PaginatedItems, PaginationResponse, Permissions } from '../types'

export enum Endpoints {
  SuicidalityEmails = '/cms/v1/global_settings/suicidality_emails.json',
  SuicidalityEmail = '/cms/v1/global_settings/suicidality_emails/%s.json',
}

export interface SuicidalityEmailResponse {
  id: string
  created_at: string
  email: string
  updated_at: string
  permissions: Permissions
}

export interface SuicidalityEmail {
  id: string
  created_at: string
  email: string
  updated_at: string
  permissions: Permissions
}

export interface GetSuicidalityEmailsResponse {
  suicidality_emails: SuicidalityEmailResponse[]
  pagination: PaginationResponse
  model_permissions: Permissions
}

const suicidalityEmailDeserializer = (
  data: SuicidalityEmailResponse,
): SuicidalityEmail => ({
  ...data,
})

export const suicidalityEmailsDeserializer = (
  data: GetSuicidalityEmailsResponse,
): PaginatedItems<SuicidalityEmail> => ({
  ...data,
  items: data.suicidality_emails.map(suicidalityEmailDeserializer),
})
